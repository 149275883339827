import classNames from 'classnames';
import { ComponentProps, h } from 'preact';

import { IconHelpBubble } from '../icons';

import { Button } from './Button';

type Props = ComponentProps<typeof Button> & {
    className?: string;
    dark?: boolean;
    size?: number;
};

export const HelpButton = ({ className, dark, size = 40, ...rest }: Props) => (
    <Button className={classNames('c-btn--help', className)} {...rest} icon>
        <IconHelpBubble
            aria-label="Need help?"
            className="u-mx-0"
            dark={dark}
            height={size}
            width={size}
        />
        <span aria-hidden="true" className="u-mr-2 xs:u-hide sm:u-hide u-font-2 u-txt-true-v">
            Help
        </span>
    </Button>
);
