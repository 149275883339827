import { h } from 'preact';
import { useMemo } from 'preact/hooks';

import { routes, links } from '../../constants';

import { Social } from './social';

const FOOTER_LINKS = [
    {
        title: 'Legal',
        url: routes.LEGAL,
    },
    {
        title: 'Lienholders',
        url: routes.LIENHOLDER_SUPPORT,
    },
    {
        title: 'Help',
        url: links.HELPDESK,
    },
    {
        title: 'FAQ',
        url: links.HUGO_FAQ,
    },
    {
        title: 'About us',
        url: routes.ABOUT,
    },
    // {
    //     title: 'Careers',
    //     url: routes.CAREERS,
    // },
];

export const Footer = () => {
    const year = useMemo(() => new Date().getFullYear(), []);

    return (
        <footer className="c-footer" role="contentinfo">
            <div className="c-footer__inner l-container">
                <nav role="navigation" className="c-footer__nav">
                    {FOOTER_LINKS.map((link) => (
                        <a className="c-footer__link" href={link.url} key={link.url}>
                            {link.title}
                        </a>
                    ))}
                </nav>

                <Social className="c-footer__social" />

                <div className="c-footer__legal">
                    <div className="u-font-bold">Popular Lab Co. {year}</div>
                    <a href={routes.LICENSES}>View our licenses</a>

                    <address>
                        <div
                            itemProp="address"
                            itemScope
                            itemType="http://schema.org/PostalAddress"
                        >
                            <span itemProp="streetAddress">8605 Santa Monica Blvd PMB 32146</span>
                            ,<br />
                            <span itemProp="addressLocality">West Hollywood</span>
                            ,&nbsp;
                            <span itemProp="addressRegion">CA</span>&nbsp;
                            <span itemProp="postalCode">90069</span>
                        </div>
                    </address>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
