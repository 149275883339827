import { h } from 'preact';
import classnames from 'classnames';

import { links } from '../../constants';

import { IconFacebook, IconTwitter, IconInstagram } from '../icons';

type Props = {
    className?: string;
    inverted?: boolean;
};

export const Social = ({ className, inverted }: Props) => (
    <div
        className={classnames('c-social', className, {
            'c-social--inverted': inverted,
        })}
    >
        <a
            aria-label="Hugo at Facebook"
            href={links.HUGO_FACEBOOK}
            rel="noreferrer noopener"
            target="_blank"
        >
            <IconFacebook title="Hugo at Facebook" />
        </a>
        <a
            aria-label="Hugo at Twitter"
            href={links.HUGO_TWITTER}
            rel="noreferrer noopener"
            target="_blank"
        >
            <IconTwitter title="Hugo at Twitter" />
        </a>
        <a
            aria-label="Hugo at Instagram"
            href={links.HUGO_INSTAGRAM}
            rel="noreferrer noopener"
            target="_blank"
        >
            <IconInstagram title="Hugo at Instagram" />
        </a>
    </div>
);
